import React from "react"
import { Row, Col, Image } from "react-bootstrap"

import ReactMarkdown from "react-markdown"
import { ensurePublicURL } from "../general"

export const MarketHeader = ({ headerData, heading, excerpt }) => {
  const marketBg = ensurePublicURL(headerData.header_bg)
  return (
    <div
      className="p-0 jumbo-gumbo parallax"
      style={{ backgroundImage: `url(${marketBg.publicURL})` }}
    >
      <div className="jumbo-overlay">
        <Row className="header-row">
          <Col xs={12} lg={6} className="text-left">
            <ReactMarkdown source={headerData.heading} />
            <p className="pt-4 pb-4">{headerData.excerpt}</p>
          </Col>
          <Col className="pt-3 text-left elevator-col" xs={12} lg={{ span: 5, offset: 1 }}>
            <h3 className="pb-3">{heading}</h3>
            <ReactMarkdown source={excerpt} />
          </Col>
        </Row>
      </div>
    </div>
  )
}
