import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import Fade from "react-reveal/Fade"
import { ensurePublicURL } from "../general"

export const ElevatorPitch = ({ opportunities, subMarketName }) => {
  return (
    <>
      <Container fluid>
        <Fade>
          <Row>
            <Col className="opportunities-container" xs={12} lg={12}>
              <h2 className="section-header pt-5 pb-5">
                CHALLENGES FACED BY <span>{subMarketName}</span>
              </h2>
              <Row>
                {opportunities.map((o, i) => {
                  let colSpan = 4
                  if (opportunities.length % 3 === 1) {
                    if (i === opportunities.length - 1) {
                      colSpan = 12
                    }
                  }
                  if (opportunities.length % 3 === 2) {
                    if (i >= opportunities.length - 2) {
                      colSpan = 6
                    }
                  }
                  return (
                    <Opportunity opp={o} key={i} index={i} colSpan={colSpan} />
                  )
                })}
              </Row>
            </Col>
          </Row>
        </Fade>
      </Container>
    </>
  )
}
const Opportunity = ({ opp, colSpan }) => {
  const appIcon = ensurePublicURL(opp.icon)
  return (
    <Col lg={colSpan} xs={12}>
      <Fade>
        <div className="pt-5 ">
          <div>
            <Image className="mt-3" src={appIcon.publicURL} thumbnail />
          </div>
          <div>
            <h5 className="pt-3 opp-heading">{opp.heading}</h5>
          </div>
        </div>
      </Fade>
    </Col>
  )
}
