import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import placeholder from "../../images/stock/placeholder.png"
import ReactMarkdown from "react-markdown"
import { TransitionLink } from "../animation/TransitionLink"
import { ensurePublicURL } from "../general"
import Fade from "react-reveal/Fade"

export const SolutionPitches = ({ pitches }) => {
  return (
    <>
      <Container fluid>
        <Fade>
          <div className="mobile-hide" style={{ height: "180px" }} />
          <h2 className="section-header challenges  pt-5 pb-5">
            HOW WE <span>ATTACK</span> THOSE CHALLENGES
          </h2>
          {pitches.map((p, index) => {
            if (!(index % 2)) {
              return <SolutionPitch key={index} pitch={p} />
            } else {
              return <SolutionPitchAlt key={index} pitch={p} />
            }
          })}

          <div style={{ height: "220px" }} />
        </Fade>
      </Container>
    </>
  )
}
const SolutionPitch = ({ pitch }) => {
  const solutionLogo = ensurePublicURL(pitch.solution.logo)
  const solutionTeasePic = ensurePublicURL(pitch.solution.tease_image)
  return (
    <Row className="pitch-row">
      <Col className="opportunities-container" xs={12} lg={12}>
        <Row>
          <Col
            span={6}
            className={`pr-0 pitch-col ${pitch.solution.page_class}`}
          >
            <Fade left>
              <div className="solution-pitch shift-up text-left">
                <div>
                  <ReactMarkdown source={pitch.heading} />
                </div>
                <div>
                  <p className="text-left pt-5">{pitch.excerpt}</p>
                </div>
                <Row className="pt-5">
                  <Col lg={7}>
                    <Image fluid src={solutionLogo.publicURL} />
                  </Col>
                  <Col lg={5} className="flex-vertical-center">
                    <TransitionLink
                      to={`/solution/${pitch.solution.page_slug}`}
                      className="custom-underline text-left"
                    >
                      READ MORE
                      <FontAwesomeIcon className="ml-2" icon={faChevronRight} />
                    </TransitionLink>
                  </Col>
                </Row>
              </div>
            </Fade>
          </Col>
          <Col lg={6} className="pl-0 pitch-col">
            <Fade right>
              <div
                className="solution-pitch-image shift-down"
                style={{
                  backgroundImage: `url(${solutionTeasePic.publicURL})`,
                  backgroundSize: "cover",
                }}
              ></div>
            </Fade>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
const SolutionPitchAlt = ({ pitch }) => {
  const solutionLogo = ensurePublicURL(pitch.solution.logo)
  const solutionTeasePic = ensurePublicURL(pitch.solution.tease_image)
  return (
    <Row className="pitch-row alt">
      <Col className="opportunities-container" xs={12} lg={12}>
        <Row>
          <Col lg={6} className="pr-0 pitch-col">
            <Fade left>
              <div
                className="solution-pitch-image shift-up"
                style={{
                  backgroundImage: `url(${solutionTeasePic.publicURL})`,
                  backgroundSize: "cover",
                }}
              ></div>
            </Fade>
          </Col>
          <Col
            span={6}
            className={`pl-0 pitch-col ${pitch.solution.page_class}`}
          >
            <Fade right>
              <div className="solution-pitch shift-down text-left">
                <div>
                  <ReactMarkdown source={pitch.heading} />
                </div>
                <div>
                  <p className="text-left pt-5">{pitch.excerpt}</p>
                </div>
                <Row className="pt-5">
                  <Col lg={7}>
                    <Image fluid src={solutionLogo.publicURL} />
                  </Col>
                  <Col lg={5} className="flex-vertical-center">
                    <TransitionLink
                      to={`/solution/${pitch.solution.page_slug}`}
                      className={`custom-underline text-left ${pitch.solution.page_class}`}
                    >
                      READ MORE
                      <FontAwesomeIcon className="ml-2" icon={faChevronRight} />
                    </TransitionLink>
                  </Col>
                </Row>
              </div>
            </Fade>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
