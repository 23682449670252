import React from "react"
import Layout from "../components/layout"
import { Container } from "react-bootstrap"
import { MarketHeader } from "../components/markets/MarketHeader"
import { ElevatorPitch } from "../components/markets/ElevatorPitch"
import { SolutionPitches } from "../components/markets/SolutionPitches"
import { Footer } from "../components/landing/_Footer"
import { ensurePublicURL } from "../components/general"

export const query = graphql`
  query SubMarketQuery($id: Int!) {
    strapiSubMarkets(strapiId: { eq: $id }) {
      elevator_description
      elevator_header
      sub_market_name
      page_slug
      solution_teases {
        excerpt
        heading
        solution {
          page_slug
          page_class
          logo {
            publicURL
          }
          tease_image {
            publicURL
          }
        }
      }
      market_header {
        excerpt
        heading
        header_image {
          publicURL
        }
        header_bg {
          publicURL
        }
      }
      market_challenges {
        heading
        icon {
          publicURL
        }
      }
      id
      strapiId
    }
  }
`

const IndexPage = ({ data }) => {
  const marketData = data.strapiSubMarkets
  return (
    <Layout
      title={
        "Gravitate DXP - " +
        marketData.market_header.heading
          .replace("#", "")
          .replace("**", "")
          .replace("**", "")
      }
      image={ensurePublicURL(marketData.market_header.header_image).publicURL}
      description={marketData.market_header.excerpt}
    >
      <Container className="body-container p-0" fluid>
        <MarketHeader
          headerData={marketData.market_header}
          heading={marketData.elevator_header}
          excerpt={marketData.elevator_description}
        />
        <ElevatorPitch
          subMarketName={marketData.sub_market_name}
          opportunities={marketData.market_challenges}
        />
        <SolutionPitches pitches={marketData.solution_teases} />
        <Footer />
      </Container>
    </Layout>
  )
}

export default IndexPage
